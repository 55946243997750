import { Injectable, inject } from '@angular/core';
import { CompierePrecisionData } from '@compiere-ws/models/compiere-precision-json';
import { AppConfig } from '@iupics-config/app.config.service';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class PrecisionDataService {
  #http = inject(ApiService);
  #config = inject(AppConfig);

  private precisionURL: string;

  getPrecisionDatas(precisionRequests: CompierePrecisionData[]): Observable<CompierePrecisionData[]> {
    if (this.precisionURL === undefined) {
      this.precisionURL = this.#config.getBackendResource('precisionData');
    }
    return this.#http.post<CompierePrecisionData[]>(`${this.precisionURL}`, precisionRequests);
  }
}
