import { Injectable } from '@angular/core';
import { CompiereLanguage } from '@compiere-ws/models/compiere-language-json';
import { AppConfig } from '@iupics-config/app.config.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class CompiereLanguageService {
  private languageUrl: string;

  constructor(
    private http: ApiService,
    private config: AppConfig
  ) {}

  getSystemLanguage(): Observable<CompiereLanguage[]> {
    this.languageUrl = this.config.getBackendResource('systemLanguage');
    return this.http.get<CompiereLanguage[]>(this.languageUrl).pipe(
      map((languages) => {
        for (const language of languages) {
          language.id = language.iso_code;
        }
        return languages;
      })
    );
  }
}
