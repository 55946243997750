import { Injectable } from '@angular/core';
import { Global } from '@iupics-manager/models/global-var';
import { environment } from 'environments/environment';
import { ApizConfig } from 'environments/environment.model';
import { isNil, merge } from 'lodash';

export type ModuleConfig = {
  enabled?: 'Y' | 'N';
  [config: string]: any;
};
@Injectable()
export class AppConfig {
  private static envVariable: any;
  private static apizConfig: ApizConfig;
  private static modulesConfig: { [moduleName: string]: ModuleConfig } = {};

  /**
   * Used in APP_INITIALIZER to load Environment variables
   * @returns
   */
  public loadEnvVariable() {
    const configFile = `assets/env.properties`;
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', configFile);

      xhr.addEventListener('readystatechange', () => {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
          const response = xhr.responseText;
          const parsedResponse = this.#parsePropertyFileToJSON(response);
          AppConfig.envVariable = parsedResponse.IUPICS;
          this.#mergeEnvVariables();
          this.#mergeModulesConfig();
          resolve(true);
        }
      });
      xhr.send(null);
    });
  }

  /**
   * Used in APP_INITIALIZER to load the config from the config-server
   * @param access_token
   * @returns
   */
  public loadConfig(access_token: string) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', `${environment.configUrl}?${new Date().getTime()}`);
      xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
      xhr.addEventListener('readystatechange', () => {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
          const result = JSON.parse(xhr.responseText);
          AppConfig.apizConfig = result;
          this.#mergeConfig();
          this.#mergeEnvVariables();
          this.#mergeModulesConfig();
          Global.perfLogActive = environment.config.perfLogActive;
          // this.logConfig();
          resolve(true);
        } else if (xhr.readyState === XMLHttpRequest.DONE) {
          reject();
        }
      });
      xhr.send(null);
    });
  }

  #keysToAvoid = new Set(['config', 'constant', 'themes']);
  #mergeEnvVariables() {
    if (AppConfig.envVariable) {
      environment.config = merge(environment.config, AppConfig.envVariable['config']);
      environment.constant = merge(environment.constant, AppConfig.envVariable['constant']);
      environment.themes = merge(environment.themes, AppConfig.envVariable['themes']);
      const keys = Object.keys(AppConfig.envVariable).filter((key) => !this.#keysToAvoid.has(key));
      for (const envKey of keys) {
        environment[envKey] = AppConfig.envVariable[envKey];
      }
    }
  }

  #mergeConfig() {
    environment.config = merge(environment.config, AppConfig.apizConfig['config']);
    environment.constant = merge(environment.constant, AppConfig.apizConfig['constant']);
    environment.themes = merge(environment.themes, AppConfig.apizConfig['themes']);
    const keys = Object.keys(AppConfig.apizConfig).filter((key) => !this.#keysToAvoid.has(key));
    for (const envKey of keys) {
      environment[envKey] = AppConfig.apizConfig[envKey];
    }
  }

  #mergeModulesConfig() {
    let modules = AppConfig.envVariable?.apiz?.modules;
    if (!modules) return;
    for (const moduleName in modules) this.addModuleConfig(moduleName, modules[moduleName]);

    modules = AppConfig.apizConfig?.['modules'];
    if (!modules) return;
    for (const moduleName in modules) this.addModuleConfig(moduleName, modules[moduleName]);
  }

  public getBackendResource(key?: string): string {
    if (key && isNil(environment.config.backend.ws.paths[key])) {
      throw new Error(`Resource for ${key} was not found in configuration.`);
    }
    return environment.config.backend.ws.url + (key ? environment.config.backend.ws.paths[key] : '');
  }

  public getBackendResourceWorkFlow(key?: string): string {
    return environment.config.backend.workflow.url + (key ? environment.config.backend.ws.paths[key] : '');
  }

  public getConstant(key: string): any {
    return environment.constant[key];
  }

  #parsePropertyFileToJSON(str: string): any {
    const result = {};
    for (const l of str.split('\n')) {
      const line = l?.trim();
      if (line && !line.startsWith('#')) {
        const datas = line.split('=');
        const keys = datas[0].split('_');
        let obj = result;
        for (let i = 0; i < keys.length; i++) {
          if (obj instanceof Object) {
            if (!obj[keys[i].trim()] && obj) {
              obj[keys[i].trim()] = {};
            }
            if (i + 1 === keys.length) {
              try {
                obj[keys[i].trim()] = JSON.parse(datas[1]);
              } catch (e) {
                obj[keys[i].trim()] = datas[1];
              }
            }
            obj = obj[keys[i].trim()];
          }
        }
      }
    }

    return result;
  }

  #logConfig() {
    console.group('config-server.yml');
    console.groupCollapsed('AppConfig.config');
    console.log(AppConfig.apizConfig);
    console.groupEnd();
    console.groupCollapsed('AppConfig.envVariable');
    console.log(AppConfig.envVariable);
    console.groupEnd();
    console.groupCollapsed('environment');
    console.log(environment);
    console.groupEnd();
    console.groupEnd();
  }

  addModuleConfig(moduleName: string, moduleConfig: ModuleConfig) {
    merge(AppConfig.modulesConfig, { [moduleName]: moduleConfig });
  }

  isModuleEnable(moduleName: string): boolean {
    const moduleConfig = AppConfig.modulesConfig[moduleName];
    if (!moduleConfig) return false;
    return moduleConfig.enabled === 'Y';
  }

  getModuleConfig(moduleName: string): ModuleConfig {
    return AppConfig.modulesConfig[moduleName];
  }
}
