// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { ApizConfig } from './environment.model';

export const environment: ApizConfig = {
  basehref: '/',
  configUrl: 'https://config-server.std.dev-k8s.apizsolutions.com/master/iupics-config-default.json',
  production: true, // TODO: check ExpressionChangedAfterItHasBeenCheckedError one day
  isDeployed: false,
  appName: 'Apiz',
  CUST_version: '{CUST_version}',
  STD_version: 'dev-4ab36ca7',
  default_language: 'fr_FR',
  webSiteUrl: 'https://www.apizsolutions.com/',
  available_languages: ['ar_TN', 'ca_ES', 'en_GB', 'en_US', 'en', 'es_ES', 'fr_FR', 'fr', 'nl_NL'],
  config: {
    perfLogActive: false,
    backend: {
      ws: {
        url: 'https://ws.std.dev-k8s.apizsolutions.com',
      },
      auth: {
        url: 'https://keycloak.std.dev-k8s.apizsolutions.com',
        realm: 'apiz',
        clientId: 'iupics',
      },
      socketcluster: {
        hostname: 'https://socket.std.dev-k8s.apizsolutions.com',
        port: undefined,
        secure: true,
      },
    },
  },
  constant: null,
  themes: null,
};
