import { Injectable } from '@angular/core';
import { SpecificWindowCompiereWS } from '@compiere-ws/models/specific-window-json';
import { WidgetDataCompiereJson } from '@compiere-ws/models/widget-center-json';
import { WidgetDataRequest } from '@compiere-ws/models/widget-data-request';
import { WidgetDataResponse } from '@compiere-ws/models/widget-data-response';
import { AppConfig } from '@iupics-config/app.config.service';
import { IupicsWidget } from '@iupics-manager/models/iupics-widget';
import { Observable, map, of, shareReplay } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class WidgetCenterService {
  private readonly widgetsURL: string;
  private readonly widgetDataURL: string;
  private readonly widgetEditorURL: string;

  private iupicsWidgets$: Observable<IupicsWidget[]>;
  private iupicsWidgets: IupicsWidget[];
  constructor(
    private http: ApiService,
    private config: AppConfig
  ) {
    this.widgetsURL = this.config.getBackendResource('widgets');
    this.widgetDataURL = this.config.getBackendResource('widgetData');
    this.widgetEditorURL = this.config.getBackendResource('widgetEditor');
  }
  resetCachingData() {
    this.iupicsWidgets$ = undefined;
    this.iupicsWidgets = [];
  }
  saveWidget(widget: IupicsWidget): Observable<IupicsWidget> {
    return this.http.post<IupicsWidget>(`${this.widgetsURL}`, widget).pipe(
      map((widgetSaved) => {
        let widgetFound = this.iupicsWidgets.find((w) => w.id == widgetSaved.id);
        if (!widgetFound) {
          this.iupicsWidgets = this.iupicsWidgets.concat(widgetSaved);
        } else {
          this.iupicsWidgets = this.iupicsWidgets.map((w) => (w.id === widgetSaved.id ? widgetSaved : w));
        }
        this.iupicsWidgets$ = null;
        return widgetSaved;
      })
    );
  }
  deleteWidget(id: number): Observable<IupicsWidget> {
    return this.http.delete<IupicsWidget>(`${this.widgetsURL}/${id}`).pipe(
      map((deleted) => {
        const indexToDelete = this.iupicsWidgets.findIndex((w) => w.id == id);
        if (indexToDelete > -1) {
          this.iupicsWidgets = [
            ...this.iupicsWidgets.slice(0, indexToDelete),
            ...this.iupicsWidgets.slice(indexToDelete + 1),
          ];
        }
        this.iupicsWidgets$ = null;
        return deleted;
      })
    );
  }
  getWidgetList(): Observable<IupicsWidget[]> {
    return this.http.get<IupicsWidget[]>(`${this.widgetsURL}`);
  }
  getWidgetDatas(widgetDataRequest: WidgetDataRequest): Observable<WidgetDataResponse> {
    return this.http.post<WidgetDataResponse>(`${this.widgetDataURL}`, widgetDataRequest);
  }
  getWidgetData(menuId: number): Observable<WidgetDataCompiereJson> {
    return this.http.get<WidgetDataCompiereJson>(`${this.widgetDataURL}/${menuId}`);
  }
  getIupicsWidget(id: number): IupicsWidget {
    return this.iupicsWidgets.find((widget) => widget.id == id);
  }
  getIupicsWidgets(): Observable<IupicsWidget[]> {
    if (!this.iupicsWidgets$) {
      this.iupicsWidgets$ = this.getWidgetList().pipe(
        map((widgets) => {
          this.iupicsWidgets = widgets;
          return this.iupicsWidgets;
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      );
    }
    return this.iupicsWidgets$;
  }

  getWidgetRoles(isAdmin: boolean, widget_id: number): Observable<number[]> {
    if (!isAdmin || widget_id == 0) {
      return of([]);
    }
    return this.http.get<number[]>(`${this.widgetsURL}/${widget_id}/roles`);
  }
  getWidgetEditorForm(): Observable<SpecificWindowCompiereWS> {
    return this.http.get<SpecificWindowCompiereWS>(this.widgetEditorURL);
  }
  getSearchColumns(widget: IupicsWidget): Observable<any[]> {
    return this.http.post<any[]>(`${this.widgetsURL}/searchColumns`, widget);
  }
}
