import { Injectable } from '@angular/core';
import { CompiereMenu } from '@compiere-ws/models/compiere-menu-json';
import { AppConfig } from '@iupics-config/app.config.service';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class CompiereMenuService {
  private url: string;

  constructor(
    private http: ApiService,
    private config: AppConfig
  ) {}

  getMenus(): Observable<CompiereMenu[]> {
    this.url = this.config.getBackendResource('menu');
    return this.http.get<CompiereMenu[]>(this.url);
  }
}
