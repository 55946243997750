import { Injectable } from '@angular/core';
import { AppConfig } from '@iupics-config/app.config.service';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class CompiereCalloutService {
  private url: string;

  constructor(
    private http: ApiService,
    private config: AppConfig
  ) {}

  getData(query: string, params: any[], tablenames: string[]): Observable<{}[]> {
    this.url = this.config.getBackendResource('DBSelect');
    return this.http.post<{}[]>(`${this.url}`, {
      query: query,
      params: params,
      tablenames: tablenames,
    });
  }
}
