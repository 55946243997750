import { colorUtils } from '../../../../../iupics-util/tools/color-utils';

export function getColorsForString(str: string) {
  const hash = str
    .replace(' ', '')
    .split('')
    .reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);

  const normalizeHash = (hash: number, min: number, max: number) => {
    return Math.floor((hash % (max - min)) + min);
  };

  const h = normalizeHash(hash, 0, 360);
  const s = normalizeHash(hash, 35, 85);
  const l = normalizeHash(hash, 65, 85);

  const bg = colorUtils.hslToHex({ h, s, l });
  const fg = colorUtils.getContrastColor(bg);

  return { bg, fg };
}

/**
 * If the account name has a space, return the first letter of the first word and the first letter of
 * the second word. If the account name has a dash, return the first letter of the first word and the
 * first letter of the second word. Otherwise, return the first two letters of the account name
 * @param {string} account_name - The account name of the user.
 * @returns The first two letters of the account name.
 */
export function getAvatarTwoLetters(account_name: string): string {
  const account_name_upper = account_name.toUpperCase();

  const spacesSplit = account_name_upper.split(' ');
  if (spacesSplit.length > 1) {
    return spacesSplit[0].charAt(0) + spacesSplit[1].charAt(0);
  }

  const dashSplit = account_name_upper.split('-');
  if (dashSplit.length > 1) {
    return dashSplit[0].charAt(0) + dashSplit[1].charAt(0);
  }

  return account_name_upper.charAt(0) + account_name_upper.charAt(1);
}
