export namespace colorUtils {
  /**
   * Adapts the color passed in parameter to have a
   * contrast ratio of at least 4.5 compare to the original color
   * @param hexColor Color to adapt in hexadecimal format
   * @returns adapted color in hexadecimal format
   */
  export function getContrastColor(hexColor: string) {
    let baseFactor = 10;
    let color = hexColor;
    const rgb = hexToRgb(color);

    let lighter: RGB;
    let lighterContrast: number;
    let darker: RGB;
    let darkerContrast: number;
    let flag = false;
    const contrastRatio = 4.5;

    do {
      lighter = getLighterLuminance(hexColor, baseFactor);
      darker = getDarkerLuminance(hexColor, baseFactor);

      lighterContrast = getContrast(lighter, rgb);
      darkerContrast = getContrast(darker, rgb);
      if (lighterContrast >= contrastRatio && darkerContrast < lighterContrast) {
        color = rgbToHex(lighter);
        flag = true;
      } else if (darkerContrast >= contrastRatio) {
        color = rgbToHex(darker);
        flag = true;
      }

      baseFactor += 10;
    } while (baseFactor <= 100 && !flag);

    if (!flag) {
      color = rgbToHex(darkerContrast < lighterContrast ? lighter : darker);
    }

    return color;
  }

  export function getLighterLuminance(hexColor: string, percentage: number): RGB {
    const rgb = hexToRgb(hexColor);
    const factor = 1 + percentage / 100;

    const r = getValueForFactor(rgb.r, factor);
    const g = getValueForFactor(rgb.g, factor);
    const b = getValueForFactor(rgb.b, factor);

    return { r, g, b };
  }

  export function getDarkerLuminance(hexColor: string, percentage: number): RGB {
    const rgb = hexToRgb(hexColor);
    const factor = 1 - percentage / 100;

    const r = getValueForFactor(rgb.r, factor);
    const g = getValueForFactor(rgb.g, factor);
    const b = getValueForFactor(rgb.b, factor);

    return { r, g, b };
  }

  function getValueForFactor(value: number, factor: number) {
    return Math.min(Math.max(0, Math.round(value * factor)), 255);
  }

  export function hslToHex({ h, s, l }: HSL) {
    l /= 100;
    const a = (s * Math.min(l, 1 - l)) / 100;

    const f = (n: number) => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color)
        .toString(16)
        .padStart(2, '0'); // convert to Hex and prefix "0" if needed
    };

    return `#${f(0)}${f(8)}${f(4)}`;
  }

  export function hexToRgb(hex: string): RGB {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return { r, g, b };
  }

  export function hexToHsl(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);

    (r /= 255), (g /= 255), (b /= 255);
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h, s;
    let l = (max + min) / 2;

    if (max == min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    h = Math.round(360 * h);
    s = s * 100;
    s = Math.round(s);
    l = l * 100;
    l = Math.round(l);

    return { h, s, l };
  }

  export function rgbToHsl({ r, g, b }: RGB): HSL {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h: number,
      s: number,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatique
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    return { h, s, l };
  }

  export function rgbToHex({ r = 0, g = 0, b = 0 }: RGB): string {
    const hexR = r.toString(16).padStart(2, '0');
    const hexG = g.toString(16).padStart(2, '0');
    const hexB = b.toString(16).padStart(2, '0');
    return `#${hexR}${hexG}${hexB}`;
  }

  export function getContrastRatio(rgb1: RGB, rgb2: RGB) {
    const lum1 = getLuminance(rgb1);
    const lum2 = getLuminance(rgb2);
    const contrast = (Math.max(lum1, lum2) + 0.05) / (Math.min(lum1, lum2) + 0.05);
    return contrast;
  }

  export function getLuminance({ r, g, b }: RGB) {
    var a = [r, g, b].map((v) => {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });

    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }

  export function getContrast(rgb1: RGB, rgb2: RGB) {
    const lum1 = getLuminance(rgb1);
    const lum2 = getLuminance(rgb2);
    const brightest = Math.max(lum1, lum2);
    const darkest = Math.min(lum1, lum2);
    return (brightest + 0.05) / (darkest + 0.05);
  }

  export interface RGB {
    r: number;
    g: number;
    b: number;
  }

  export interface HSL {
    h: number;
    s: number;
    l: number;
  }
}
